import styled from "styled-components";

import { FourZeroFourStyledProps as Props } from "./FourZeroFour.types";
import { getMaxHeight, getMaxWidth } from "utils/styling.utils";

const FourZeroFourStyled = styled.div<Props>`
  width: ${getMaxWidth()};
  height: ${getMaxHeight()};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--palette-white);

  .FourZeroFour {
    &__main {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &__info {
      color: var(--palette-black-s0-l10);
      font-size: 2rem;
      line-height: 2.8rem;
      padding-top: 1.6rem;
      font-weight: 600;
    }

    &__icon {
      width: 12.8rem;
      height: 12.8rem;
      border-radius: 90%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--palette-gray-s0-l98);
    }

    &__description {
      color: var(--palette-black-s0-l10);
      line-height: 2.8rem;
      font-size: 3.2rem;
      padding-top: 1.2rem;
      padding-bottom: 4.8rem;
      text-align: center;
    }

    &__button {
      font-size: 1.6rem;
      font-weight: 600;
      padding: 1.6rem 8rem;
    }
  }
`;

export default FourZeroFourStyled;
